<template>
  <div class="coming-soon">
    <h1>{{ brandName }}</h1>
    <h2>{{ slogan }}</h2>
    <div class="countdown">
      <div v-for="(value, unit) in countdown" :key="unit" class="countdown-item">
        <span class="number">{{ value }}</span>
        <span class="unit">{{ unit }}</span>
      </div>
    </div>
    <p>Get ready for a revolution in frozen cuisine!</p>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon',
  data() {
    return {
      brandName: 'Krumbs',
      slogan: 'Freezer to Feast',
      launchDate: new Date('2024-10-31T00:00:00'),
      countdown: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const difference = this.launchDate - now;

      this.countdown.days = Math.floor(difference / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      this.countdown.minutes = Math.floor((difference / 1000 / 60) % 60);
      this.countdown.seconds = Math.floor((difference / 1000) % 60);
    }
  }
}
</script>

<style scoped>
.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #e0f7fa, #80eaa7);
  color: #006064;
  font-family: Arial, sans-serif;
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.countdown {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-size: 2.5rem;
  font-weight: bold;
}

.unit {
  font-size: 0.8rem;
  text-transform: uppercase;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
</style>